import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(private router: Router) {}

  public redirectToCurrentRouteWithoutQueryParams() {
    const currentUrl = window.location.pathname;

    this.router.navigate([currentUrl], {
      queryParams: {},
      queryParamsHandling: 'merge', // Garante que os outros parâmetros sejam mantidos, caso existam
      replaceUrl: true, // Substitui a entrada no histórico
    });
  }
}
