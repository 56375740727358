import { catchError, finalize, map, of } from 'rxjs';

import { AuthLoaderService } from './auth-loader.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RedirectService } from '../../shared/services/redirect.service';
import { Roles } from '../../shared/models/roles';
import { Router } from '@angular/router';
import { TenantOptions } from '../../shared/models/tenant-options';
import { ToastService } from '../../shared/services/toast.service';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly API_URL = environment.apiUrl;
  private readonly LOGIN_URL = environment.loginURL;
  private readonly TM_PANEL_URL = environment.tmPanel;

  public redirectUrl: string | null = null;

  constructor(
    private http: HttpClient,
    private authLoaderService: AuthLoaderService,
    private toastService: ToastService,
    private router: Router,
    private redirectService: RedirectService
  ) {}

  public login(email: string, password: string) {
    this.authLoaderService.setLoading(true);

    return this.http.post(`${this.API_URL}/login`, { email, password }).pipe(
      map((response: any) => this.handlerSuccess(response)),
      catchError((error) => this.handlerError(error)),
      finalize(() => this.authLoaderService.setLoading(false))
    );
  }

  private handlerSuccess(response: any) {
    const redirectTo = this.redirectUrl || '/';

    localStorage.setItem('authToken', response.token);

    delete response.token;

    localStorage.setItem('user', JSON.stringify(response));
    this.toastService.show('success', 'Login efetuado com sucesso');
    this.router.navigate([redirectTo]);

    return response;
  }

  private handlerError(error: any) {
    const message = error.error.message || 'Erro no login';
    this.toastService.show('error', message);

    return error;
  }

  public isLoggedIn() {
    return !!localStorage.getItem('user');
  }

  public logout(redirectURL?: string): void {
    localStorage.clear();

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete('token');

    const redirectUrl = redirectURL
      ? `${this.LOGIN_URL}?redirect=${redirectURL}`
      : `${this.TM_PANEL_URL}/logout`;

    window.location.href = `${this.TM_PANEL_URL}/logout`;
  }

  public getUserInfo(): User {
    return JSON.parse(localStorage.getItem('user') as string);
  }

  public getUserRoles(): Roles[] {
    const user = this.getUserInfo();
    return user.roles;
  }

  public getUserTenantOptions(): TenantOptions[] {
    const user = this.getUserInfo();
    return user.tenant_options.enabled_features;
  }

  public hasRole(role: Roles): boolean {
    return this.getUserRoles().includes(role as Roles);
  }

  public hasTenantOption(tenantOption: TenantOptions): boolean {
    return this.getUserTenantOptions().includes(tenantOption as TenantOptions);
  }

  public getUserData(redirectURL?: string) {
    this.authLoaderService.setLoading(true);

    return this.http.get(`${this.API_URL}/login/my_data`).pipe(
      map((response) => {
        localStorage.setItem('user', JSON.stringify(response));

        this.redirectService.redirectToCurrentRouteWithoutQueryParams();
        return true;
      }),
      catchError(() => {
        this.toastService.show('error', 'Erro ao buscar dados do usuário');
        this.logout(redirectURL);
        return of(false);
      })
    );
  }
}
